import { faDiscord, faShopify } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Checkbox, createStyles, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Theme, withStyles } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { purple, yellow } from '@material-ui/core/colors'
import moment from 'moment'
import React, { useState } from 'react'
import { useAccount } from '../../utils/AccountContext'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            // backgroundColor: theme.palette.background.default,
        },
        button: {
            margin: theme.spacing(1),
        },
    }),
);

const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);

  const YColorButton = withStyles((theme: Theme) => ({
    root: {
      color: theme.palette.getContrastText(yellow[500]),
      backgroundColor: yellow[500],
      '&:hover': {
        backgroundColor: yellow[700],
      },
    },
  }))(Button);

const AccountStatus: React.FC = () => {
    const account = useAccount()
    // const dialog = useDialog()
    const classes = useStyles()

    // const loadStatus = useCallback(()=>{
    //     dialog.showLoading('Carregando informações')
    //     account.loadAccountInfo().then(info => {
    //         dialog.closeLoading()

    //         if (info != null)
    //             setAccountInfo(info)
    //         else
    //             dialog.showSnackbar('Erro ao carregar status da conta, tente novamente', 'error')

    //     })
    // }, [setAccountInfo, account, dialog])

    const listChar = () => {

        account.accountInfo && account.accountInfo.chars.map((e, index) => {

            return (
                <React.Fragment key={index}>
                    <p><strong>{e.name}</strong> Level:{e.level}. Resets:{e.resets} </p>
                </React.Fragment>
            )
        })

        return <List dense className={classes.root}>
            {account.accountInfo?.chars.map((e, index) => {
                const labelId = `checkbox-list-secondary-label-${e.name}`;
                let avatar = undefined
                switch (Number(e.class)) {
                    case 0:
                        avatar = 'avatarSM.png'
                        break;
                    case 1:
                        avatar = 'avatarSM.png'
                        break;
                    case 16:
                        avatar = 'avatarBK.png'
                        break;
                    case 17:
                        avatar = 'avatarBK.png'
                        break;
                    case 32:
                        avatar = 'avatarELF.png'
                        break;
                    case 33:
                        avatar = 'avatarELF.png'
                        break;
                    case 48:
                        avatar = 'avatarMG.png'
                        break;
                }
                return (
                    <ListItem key={index} button>
                        <ListItemAvatar>
                            <Avatar
                                alt={`Avatar`}
                                src={avatar}
                            />
                        </ListItemAvatar>
                        <ListItemText id={labelId} primary={e.name} />
                        <ListItemSecondaryAction>
                            <ListItemText id={labelId} primary={`Resets: ${e.resets}`} />
                            {/* <Checkbox
                                edge="end"
                                onChange={handleToggle(value)}
                                checked={checked.indexOf(value) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                            /> */}
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    }

    return (
        <Collapse in={account.accountInfo != null}>
            <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                    <p><strong>Status da conta</strong></p>
                    <p>Último login: {(account.accountInfo?.status?.connectTM != null &&
                        moment(account.accountInfo.status.connectTM, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY HH:mm')
                    ) || '?'}</p>
                    <p className={
                        account.accountInfo?.status?.connectStat === 1 ? 'text-success' : 'text-danger'
                    }>Status: {account.accountInfo?.status?.connectStat === 1 ? 'Online' : 'Offline'}</p>
                    <p>Último IP: {account.accountInfo?.status?.ip || '?'}</p>
                    <p>Email: {account.accountInfo?.info?.mailAddr || '?'}</p>
                    <p>Moedas: <em>{account.accountInfo?.info?.moedas || '?'}</em></p>
                </Grid>

                <Grid item md={6} xs={12}>
                    <p><strong>Personagens:</strong></p>
                    {listChar()}
                </Grid>
                <Grid item md={6} sm={12}>
                    <label htmlFor="icon-button-file">
                        {/* <IconButton onClick={() => { }} color="secondary" aria-label="upload picture" component="span">
                            <FontAwesomeIcon icon={faDiscord} />
                        </IconButton> */}
                        <ColorButton
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={()=>{window.open("https://discord.gg/EUUPyKWNdf", 'blank')}}
                            startIcon={<FontAwesomeIcon color={'white'} icon={faDiscord} />}
                        >
                            {`Entrar no discord`}
                        </ColorButton>
                    </label>
                </Grid>
            </Grid>
        </Collapse>

    )
}

export default AccountStatus;