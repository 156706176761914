import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AccountStatus from './AccountStatus';
import ChangePassword from './ChangePassword';
import { useDialog } from '../../utils/Dialog';
import LoadingSpinner from '../../components/LoadingSpinner';
import { ItemPurchased, ItemShop, ResponseDefault } from '../../config/types';
import { sendGetRequest } from '../../config/Api';
import ShopCatalog from '../Shop/ShopCatalog';
import { useAccount } from '../../utils/AccountContext';
import ShopPurchases from '../Shop/Purchases';
import Admin from '../Admin/admin';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
  },
}));

export default function Account() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const dialog = useDialog()
  const account = useAccount()
  const [chaveAdmin, setChaveAdmin] = useState<string>("")



  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    // if (newValue === 1)
    //   dialog.showAlertDialog({
    //     title: 'Shop em manutenção',
    //     message: 'No momento o shop está indisponível, tente novamente mais tarde'
    //   })
  };

  useEffect(() => {
    if (value === 4 && chaveAdmin === '') {
      dialog.showInputDialog({
        title: 'Autenticar como Administrador',
        message: 'Digite a chave interna para acessar o painel de ADM:',
        inputProps: {
          maxLength: 10,
          inputType: 'password'
        },
        buttons: [
          { text: 'CANCELAR' },
          {
            text: 'ENTRAR', required: true, action: (value) => {
              if (value != null && value === '97OLDF') {
                setChaveAdmin(value)
                dialog.showSnackbar('Tudo jóia, vamos prosseguir =)', 'success')
              } else {
                setChaveAdmin('')
                dialog.showSnackbar('Falha na autenticação...', 'error')
              }
            }
          }
        ]
      })
    }
  }, [chaveAdmin, value, dialog, setChaveAdmin])

  return (
    account.token == null ? <></> :
      <React.Fragment>
        {/* <Typography component="h2">Minha conta</Typography> */}
        <div className={classes.root}>
          {/* <AppBar classes={{}} component={Box} position="static"> */}
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Status da conta" {...a11yProps(0)} />
              <Tab label="Shop" {...a11yProps(1)} />
              <Tab label="Meus itens" {...a11yProps(2)} />
              <Tab label="Trocar senha" {...a11yProps(3)} />
              {account.accountInfo?.accessLevel === 'Admin' && <Tab label="Admin" {...a11yProps(4)} />}
            </Tabs>
          {/* </AppBar> */}
          <TabPanel value={value} index={0}>
            <AccountStatus />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LoadingSpinner<ResponseDefault<ItemShop[]>>
              promise={async () => {
                const response = await sendGetRequest('shop/items');

                if(response.status != 200) {
                  dialog.showSnackbar('Falha ao carregar dados do shop...', 'error');
                  setValue(0)
                  throw Error()
                }

                return response.json()
              }}
              onSuccess={(json) => {
                return <ShopCatalog items={json.response.filter(item => Number(item.insertShop) === -1)}></ShopCatalog>
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LoadingSpinner<{itemsShop: ItemShop[], myItems: ItemPurchased[]}>
              promise={async () => {
                const response = await sendGetRequest('shop/items');
                const response2 = await sendGetRequest('shop/purchased-items');

                if(response.status != 200 || response2.status != 200) {
                  dialog.showSnackbar('Falha ao carregar dados do shop...', 'error');
                  setValue(0)
                  throw Error()
                }

                const retorno = {
                  itemsShop: (await response.json()).response as ItemShop[],
                  myItems: (await response2.json()).response as ItemPurchased[]
                }
                console.log(response2)
                return retorno
              }}
              onSuccess={(json) => {
                return <ShopPurchases itemsShop={json.itemsShop} myItems={json.myItems} />
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ChangePassword />
          </TabPanel>
          {
            account.accountInfo?.accessLevel === 'Admin' && chaveAdmin !== '' && <TabPanel value={value} index={4}>
              <Admin accountInfo={account.accountInfo!!}></Admin>
            </TabPanel>
          }
        </div>
      </React.Fragment>
  )
}