import { Box, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { sendRequest } from '../../config/Api';
import { BuyItemParams, ItemShop } from '../../config/types';
import { useAccount } from '../../utils/AccountContext';
import { useDialog } from '../../utils/Dialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: 'fit-content',
        },
        formControl: {
            marginTop: theme.spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: theme.spacing(1),
        },
    }),
);

type BuyItemDialogProps = {
    item?: ItemShop
    onClose: () => void
}

const BuyItemDialog: React.FC<BuyItemDialogProps> = ({ item, onClose }: BuyItemDialogProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const classes = useStyles()
    const dialog = useDialog()
    const account = useAccount()
    const [asaLvl2OptHp, setAsaLvl2OptHp] =  useState<boolean>(false)

    const isWingsLevel2 = useCallback((item)=> {
        return item!= undefined &&
                item!.itemType != undefined &&
                item.itemType == 12 &&
                [3,4,5,6].includes(item.itemIndex)
    }, [])

    const handleBuyItem = useCallback(async () => {
        if (item != null) {
            dialog.showLoading('Finalizando compra')
            try {
                const body: BuyItemParams = {
                    wingIncreaseHp: isWingsLevel2(item) && asaLvl2OptHp
                }
                const response = await sendRequest(`shop/buy-item/${String(item.number)}`, body)
                const data = await response.json()
                if (data.status === "SUCCESS") {
                    dialog.showSnackbar('Compra realizada com sucesso!', 'success')
                    account.loadAccountInfo()
                    onClose()
                }else{
                    dialog.showSnackbar(data.error || 'Erro desconhecido, tente novamente mais tarde', 'warning')
                }
            } catch (error) {
                console.log(error)
                dialog.showSnackbar('Erro desconhecido, tente novamente mais tarde', 'error')
            }finally{
                dialog.closeLoading()
            }
        }
    }, [item, onClose, dialog, account, asaLvl2OptHp, isWingsLevel2])

    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                maxWidth={"md"}
                open={item != null}
                onClose={onClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Comprar {item?.itemName}</DialogTitle>
                <DialogContent>
                    <Box component="div" display="flex" flexDirection="column" alignItems="center" width="100%">
                        <Box component="div">
                            <img src={`http://www.mufantasy.com.br/shop/${item?.photoItem || ''}`} alt="..." />
                        </Box>
                    </Box>
                    <DialogContentText>
                        {"Ao clicar em Finalizar compra não será possível cancelar a compra!"}
                    </DialogContentText>
                    {isWingsLevel2(item) && (
                        <Box component="div" display="flex" flexDirection="row" alignItems="flex-end" width="100%" >
                            <Checkbox
                                checked={asaLvl2OptHp}
                                onChange={(event) => setAsaLvl2OptHp(event.target.checked)}
                                name="checkedB"
                                color="primary"
                            />
                            <DialogContentText>
                                {"Atribuir opção Dano adicional +28"}
                            </DialogContentText>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {"Cancelar"}
          </Button>
                    <Button onClick={handleBuyItem} variant={"contained"} color="primary">
                        {"Finalizar compra"}
          </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default BuyItemDialog